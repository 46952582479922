import { useState, useEffect } from 'react';

function useFetchLocationData(id) {
  const [location, setLocation] = useState([]);

  useEffect(() => {

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/locationById`, {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(res => res.json())
        .then(data => setLocation(data));
    },[])

  return {location};
}

export default useFetchLocationData;