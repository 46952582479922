import React, { useEffect,useState } from 'react'
import {Divider,  Table,Grid, Button, Select, MenuItem,Container ,Stack,Box,InputLabel, Typography, TableHead, TableRow, TableCell,useTheme, TableBody, TableContainer} from '@mui/material';
import { useUser } from "@clerk/clerk-react";
import Cookies from 'js-cookie';
import useFetchUserData from '../../hooks/useFetchUserData';
import useFetchLocations from '../../hooks/useFetchLocations';
import Papa from 'papaparse';
export default function Reports({loading}) {

  const { isSignedIn, user, isLoaded } = useUser();
const [confirmPrint, setConfirmPrint] = useState(false);

  const [chosenLocation, setChosenLocation] = useState(0);
  const [chosenOrder, setChosenOrder] = useState(0);
  const [orderType, setOrderType] = useState('choose');
  const [orders, setOrders] = useState([]);
  const [results, setResults] = useState(null);
  const theme = useTheme();
  const accounts=[{username:'1',name:'1'}]
  // const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].username;
  const name = accounts[0] && accounts[0].name;
  const location_id = Cookies.get('location_id');
  const [listLocations, setListLocations] = useState([]);
// console.log("user",user.emailAddresses[0].emailAddress)
// console.log("level",user?.publicMetadata?.level)

  const handleSubmit = (output) => {
    let endpoint='';
    if(output=='finalise'){
      endpoint='reportsFinalise'
  }
    else if(orderType=='choose' || orderType=='open'){
        endpoint='reports'
    }else{
      endpoint='reportsClosed'
    }
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/${endpoint}`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          location_id: chosenLocation,
          order_type: orderType,
          order_date: chosenOrder,
          output: output,email_address:user.emailAddresses[0].emailAddress
        })
      }).then(res => res.json()).then(data => {
        console.log("data",data)
        if(data.status=='success'){
          if(output=='screen'){}else if(output=='finalise'){
            alert("Order Book has been finalised and emailed to you")
          }else{
        alert("Report has been emailed to you")
      }
        }else{
          alert("There was an error")
        }
        if(output=='screen'){
        Papa.parse(data.data, {
          header: true,
          complete: (result) => {
            // Assuming you want to manipulate the data to include a 'Total Qty' column
            const dataWithTotalQty = result.data.map(row => {
              const totalQty = Object.keys(row)
                .filter(key => key !== 'Product Name' && key !== 'Total Qty')
                .reduce((sum, key) => sum + parseInt(row[key] || 0, 10), 0);
              return { ...row, 'Total Qty': totalQty };
            });
            // console.log("dataWithTotalQty",dataWithTotalQty)
            setResults(dataWithTotalQty);
          }
        });
      }else{}

      }).catch(err => console.log(err))
  };

  const { userData,userLevel, locationId, allowedLocations } = useFetchUserData(user.emailAddresses[0].emailAddress);
  const locations = useFetchLocations(userLevel);
console.log("allowedLocations",allowedLocations)
// console.log("userData",userData)
// console.log("userLocation",locationId)
// console.log("userLevel",userLevel)
// console.log("userallowedLocations",allowedLocations)



  //GET ORDERS----
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/orders`).then(res => res.json()).then(data => {
      setOrders(data)
    })
  },[])

  //GET Locations----

  useEffect(() => {
    if(location_id==100 || location_id==101){

    }
    if(location_id==100 || location_id==101){

    }
    else{
      setChosenLocation(location_id)
    }
  },[location_id])

//take a var locations and remove the ones that are not in allowedLocations
//setLocations(allowedLocations)
useEffect(() => {

  if((allowedLocations.length>0 & locations.length>0 && allowedLocations[0] !=='')){
    console.log("allowedLocations",allowedLocations)
    const listLocations =locations.filter(location => allowedLocations.includes(location.id.toString()));
    setListLocations(listLocations)
  }else{
    setListLocations(locations)
  }



},[allowedLocations,orderType])


useEffect(() => {
  const findVirtualScroller = () => {
      const virtualScrollerElement = document.querySelector(
          ".MuiDataGrid-virtualScroller"
      );
      if (!virtualScrollerElement) {
          setTimeout(findVirtualScroller, 100);
      } else {
          virtualScrollerElement.addEventListener(
              "scroll",
              handleScrollHorizontal
          );
          return () => {
              virtualScrollerElement.removeEventListener(
                  "scroll",
                  handleScrollHorizontal
              );
          };
      }
  };
  findVirtualScroller();
  }, []);
  const handleScrollHorizontal = () => {
    const currentScrollPos = document.querySelector(
        ".MuiDataGrid-virtualScroller"
    ).scrollLeft;
    const columnsHeaders = document.querySelectorAll(
        ".MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:first-child)"
    );
    columnsHeaders.forEach((columnHeader) => {
        columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
    });
  }

const columns = results && results.length > 0 ? Object.keys(results[0]).filter(key => key !== 'Product Name' && key !== 'Total Qty') : [];
  return (
    <Container maxWidth="lg">
      <Grid container>

        <Grid item={true} xs={12} sm={3} md={3}lg={2} sx={{borderRight:'1px solid #EDEDED'}}>
          <Stack direction="column" alignItems="flex-start" spacing={2} sx={{borderRadius:2,p:2,background:'#ffffff'}}>
            <Typography variant="h6">Filter Orders</Typography>
            <Box sx={{width:'100%'}}>
              <InputLabel>Order Type</InputLabel>
              <Select fullWidth sx={{height:40}}
                value={orderType}

                onChange={(e) => setOrderType(e.target.value)}
              >
                <MenuItem value="choose">Choose Type</MenuItem>
                  <MenuItem value="open">Open Orders</MenuItem>
                  <MenuItem value="closed">Closed Orders</MenuItem>
              </Select>
            </Box>
            {orderType=='closed' &&
              <Box sx={{width:'100%'}}>
                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                <Select fullWidth sx={{height:40}}
                  value={chosenOrder}
                  onChange={(e) => setChosenOrder(e.target.value)}
                >
                  <MenuItem value={0}>Choose Order</MenuItem>
                  {orders && orders.map((order,index) => (
                    <MenuItem key={index} value={`${order.id}`}>{order.month}-{order.year}</MenuItem>
                  ))}
                </Select>
              </Box>
            }
{orderType !='choose' &&
            <Box sx={{width:'100%'}}>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>

            {(allowedLocations &&  allowedLocations.length>0 || userLevel=='superadmin' || userLevel=='admin') &&
              <Select fullWidth sx={{height:40}}
              value={chosenLocation}
              onChange={(e) => setChosenLocation(e.target.value)}
              >
                <MenuItem value={0}>All Locations</MenuItem>

                {listLocations && listLocations.map((location,index) => (
                    <MenuItem key={index} value={location.id}>{location.name}</MenuItem>
                ))}

              </Select>

               }
            </Box>
            }
            <Box sx={{width:'100%'}}>
              <Button fullWidth onClick={()=>handleSubmit('screen')} variant="contained">
                Show Results
              </Button>
            </Box>
            {(location_id==101 || location_id==100) &&
            <Box sx={{width:'100%'}}>
              <Button sx={{'&:hover':{
                backgroundColor:theme.palette.secondary.dark
              },backgroundColor:theme.palette.secondary.main}} fullWidth onClick={()=>handleSubmit('csv')} variant="contained">
                Generate & Email CSV
              </Button>
            </Box>
            }
            {location_id==101 &&
            <Box sx={{width:'100%'}}>
              {!confirmPrint &&
              <Button variant="contained"  sx={{background:'#000'}} onClick={()=>setConfirmPrint(true)}>Are you ready to finalise the order for the month?</Button>}
              {confirmPrint &&
              <Button sx={{'&:hover':{
                backgroundColor:'#ca6021'
              },backgroundColor:'#ca6021'}} fullWidth onClick={()=>handleSubmit('finalise')} variant="contained">
                Close orders & Email CSV
              </Button>
              }
            </Box>
            }
          </Stack>
        </Grid>
        <Grid item={true} xs={12} sm={9} md={9}lg={10}>
            <>
            {results==null ?
              <Box sx={{p:2}}>
                <Typography>Choose your filters to get reports</Typography>
              </Box>
            :<>
            {results.length==0 ?
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{p:2}}>
              <Typography>Choose your filters to get reports</Typography>
              </Stack>:
              <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{p:2}}>
               <Stack direction="row" sx={{width:'100%'}} spacing={3}>
                {/* {chosenLocation==0 ? <Typography>Location: All</Typography> : <Typography>Location: {chosenLocation}</Typography>} */}
                {orderType == 'open' ? <Typography>Order Type: Open</Typography> : <Typography>Order Type: Closed</Typography>}
                </Stack>
<TableContainer sx={{ maxHeight: 440,background:'#fff' }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell sx={{fontWeight:'bold'}}>Product</TableCell>
          {columns.map((column, index) => (
            <TableCell key={index} sx={{fontWeight:'bold'}}>{column}</TableCell>
          ))}
          {/* <TableCell sx={{fontWeight:'bold'}}>Total Qty</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map((result, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell>{result['Product Name']}</TableCell>
            {columns.map((column, colIndex) => (
              <TableCell key={colIndex}>{result[column]}</TableCell>
            ))}
            {/* <TableCell>{result['Total Qty']}</TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
              </Stack>
            }
            </>
            }
            </>
          </Grid>
      </Grid>
    </Container>
  )
}
