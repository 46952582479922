import React from 'react'
import { SignInButton } from "@clerk/clerk-react"
import { light } from '@clerk/themes';
import { Button } from '@mui/material';

export default function SignUp() {
  return (
    <><h5 className="card-title">Sign-up is disabled for this site</h5>
    <Button variant="contained" href="/sign-in?redirect_url=%2F">Sign In</Button>
    </>
  )
}
