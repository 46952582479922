import React,{useState,useEffect} from 'react'
// import { useIsAuthenticated } from "@azure/msal-react";
// import { SignInButton } from "../../components/auth/SignInButton";
// import { SignOutButton } from "../../components/auth/SignOutButton";
// import { useMsal } from "@azure/msal-react";
import {Box,Stack,Button, Typography, Link} from '@mui/material';
import { SignOutButton, SignInButton, SignedIn, SignedOut,useUser } from "@clerk/clerk-react"
import { dark } from '@clerk/themes';
import Cookies from 'js-cookie';
 import useFetchUserData from '../../hooks/useFetchUserData';
export default function Layout({children}) {
  const { isSignedIn, user, isLoaded } = useUser();
  const [username, setUsername] = useState(null);
  const [location_id, setLocation_id] = useState(null);
  const [name,setName]=useState(null);
  const { userData, userLevel,locationId, allowedLocations } = useFetchUserData(username);

  useEffect(() => {

     if(typeof(user) !==undefined && user && user.emailAddresses &&  user.emailAddresses[0].emailAddress){
       setUsername(user.emailAddresses[0].emailAddress)
       //console.log("user",user)
     }
    if(typeof(user) !==undefined && user && user.firstName){
      setName(user.firstName)
    }
  }, [user]);




  // const isAuthenticated = useIsAuthenticated();
  // const { accounts } = useMsal();
  // const accounts=[{username:'1',name:'1'}]
  // const username = accounts[0] && accounts[0].username;
  //  const name = accounts[0] && accounts[0].name;
// console.log("user",userLevel)
  return (
    <Box component="main" sx={{backgroundColor:'#f0f1f5',minHeight:'100vh'}}>
      <Box component="header" sx={{px:4,background:'#fff',height:username =='' ? '100vh' :'auto'}}>
        <Stack direction="row" spacing={2} sx={{alignItems:"center",justifyContent:"space-between"}}>
          <Stack direction="row" alignItems="center" spacing={2}>
           <Link href="/"><Box component="img" src="/logo-group.png" alt="Westerleigh Group" sx={{width:200}} /></Link>
            <Stack direction="row" alignItems="flex-start" spacing={4}>
              <Box>
              <Stack direction="row" spacing={2}>
                <Button variant="text" sx={{color:'#000'}} href="/">Order Form</Button>
                {(userLevel =='superadmin' || userLevel =='admin' || userLevel =='hod' || userLevel =='dom') &&
                  (<Button  sx={{color:'#000'}}  variant="text" href="/reports">Reports</Button>)
                }
                {(userLevel =='superadmin' || userLevel =='admin') &&
                   <Button sx={{color:'#000'}}  variant="text" href="/users">Users</Button>
              }
               {(userLevel =='superadmin' || userLevel =='admin') &&
                   <Button sx={{color:'#000'}}  variant="text" href="/locations">Locations</Button>
              }

                </Stack>
              </Box>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0}>
            {/* <SignedOut>
              <SignInButton appearance={{baseTheme: dark}} />

            </SignedOut > */}

            <SignedIn>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box>Welcome {name}</Box>

                <SignOutButton style={{color:'#FFF',background:'#3069cb',border:0,fontSize:12,padding:5,borderRadius:10}}  afterSignOutUrl="/" appearance={{baseTheme: dark}}  />
              </Stack>
            </SignedIn>
            {/* { isAuthenticated   && <Typography variant="body1" sx={{fontSize:13}}>Welcome {name} |</Typography>}
              <Box>{ isAuthenticated ? <SignOutButton /> : <SignInButton /> }</Box> */}
          </Stack>
        </Stack>
      </Box>
      <Box sx={{p:4}}>
      {children}
      </Box>
    </Box>
  )
}
