import React from 'react'
import { SignInButton } from "@clerk/clerk-react"
import { light } from '@clerk/themes';
import { Button } from '@mui/material';

export default function DashOut() {
  return (
    <><h5 className="card-title">Please sign-in to access your order sheet</h5>
    {/* <Button variant="contained" href="/sign-in?redirect_url=%2F">Sign In</Button> */}
    <SignInButton style={{color:'#FFF',background:'#3069cb',border:0,fontSize:16,padding:10,borderRadius:10}} />
    </>
  )
}
