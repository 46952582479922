import { createTheme } from '@mui/material/styles';

const theme = createTheme({
 palette:{
  primary:{
    main:'#3069cb'
  },
  secondary:{
    main:'#409a3d',
    dark:'#27551A'
  },
  text:{
    primary:'#000000',
    secondary:'#000000'
  }
 },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize:14,
    h5: {
      fontSize: '1rem' // 12px
    },
    h6: {
      fontSize: '1rem' // 12px
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 400
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 400
        }
      }
    },MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 400
        }
      }
    },MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 13,
          textTransform:'none'
        }
      }
    }
  }


});

export default theme;