import { useState } from 'react';

function useCreateUser() {
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const createUser = (userData, callback) => {
    console.log("userData", userData);
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/userCreate`, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json()) // Assuming the response needs to be converted from JSON
    .then(data => {
      setResponse(data);
      setIsLoading(false);
      if (callback && typeof callback === 'function') {
        callback(null, data); // First parameter is error, which is null in this case
      }
    })
    .catch(err => {
      setError(err);
      setIsLoading(false);
      if (callback && typeof callback === 'function') {
        callback(err, null);
      }
    });
  };

  return { createUser, response, error, isLoading };
}

export default useCreateUser;