import React, { useEffect,useState } from 'react'
import { Table,Grid,Container ,Box, Typography, TableHead, TableRow, TableCell, TableBody, Link, Chip,Stack,Button,Alert, Paper} from '@mui/material';
import { useUser } from "@clerk/clerk-react";
// import useFetchLocationData from '../../hooks/useFetchLocationData';
import DeleteLeft from 'src/theme/icons/DeleteLeft';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Locations({loading}) {

  const { isSignedIn, user, isLoaded } = useUser();
  const [results, setResults] = useState(null);
  const [locations, setLocations] = useState([]);
// console.log("user",user.emailAddresses[0].emailAddress)
// console.log("level",user?.publicMetadata?.level)

  const handleSubmit = (output) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          location_id: chosenLocation,
          order_type: orderType,
          order_date: chosenOrder,
          output: output
        })
      }).then(res => res.json()).then(data => {
        console.log("data",data)
        setResults(data)
      }).catch(err => console.log(err))
  };
  // const { userData,userLevel, locationId, allowedLocations } = useFetchLocationData(location);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const[deleteId,setDeleteId]=useState(null);
  const[deleteLocation,setDeleteLocation]=useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
const handleDelete = async (id,name) => {
    setOpen(true)
    setDeleteId(id)
    setDeleteLocation(name)
}
const handleCompleteDelete = async () => {
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/locationDelete/`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: deleteId,
    })
  })
  .then(res => res.json())
  .then(data => {
    console.log("data",data)
    setDeleteStatus(true)
    //waits for 4 seconds to refresh the page
  //   setTimeout(function(){

  //   window.location.reload();
  // }, 4000);
  }).catch(err => console.log(err))

}
  //GET ORDERS----
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`).then(res => res.json()).then(data => {
      setLocations(data)
    })
  },[])


  return (
    <Container maxWidth="lg">
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {deleteStatus &&
          <>
         <Alert severity="success" sx={{mb:2}}>The location has been deleted.

         </Alert>
         <Button sx={{background:'#CCC'}}variant="contained" onClick={handleClose}>Close Window</Button>
</>
          }
          {deleteStatus==false &&
          <>
          <Typography sx={{fontWeight:600}} variant="h5" component="h5">
            Delete Location
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete <strong>{deleteLocation}</strong>?
          </Typography>



          <Stack direction="row" justifyContent="space-between" sx={{mt:2}}>
            <Button sx={{background:'#CCC'}}variant="contained" onClick={handleClose}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={handleCompleteDelete}>Delete</Button>
          </Stack>
          </>
}
        </Box>
      </Modal>
    </div>
      <Grid container>
        <Grid item={true} xs={12} sm={12} md={12}lg={12}>
            <>
            <Stack direction="row" justifyContent="flex-end">
            <Button href="/createLocation" variant="contained" sx={{mb:2,
            cursor:'pointer',
            '&:hover':{
              color:'#FFF'
            }
            }}>Create Location</Button>
            </Stack>

            {locations==null ?
              <Box sx={{p:2}}>
                <Typography>There are no locations</Typography>
              </Box>
            :
            <Paper>
            <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontWeight:'bold'}}>Name</TableCell>
                      <TableCell sx={{width:10}}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {locations && locations.map((location,index) => {
console.log("user.level",user)
                    // if(user.level =='superadmin' || user.level =='admin'){
                    return (
                    <TableRow key={index} sx={{verticalAlign:'top'}}>
                        <TableCell sx={{flex:1}}>
                          <Link href={`/editLocation/${location.id}`}>{location.name}</Link>
                        </TableCell>

                        <TableCell sx={{width:10}}><DeleteLeft sx={{cursor:'pointer','&:hover':{opacity:0.5,transition:'all 0.3s ease-in-out'
                  }}} onClick={(e)=>handleDelete(location.id)} /></TableCell>
                      </TableRow>
                    )
                  // }
                    })}
                  </TableBody>
                </Table>

                </Paper>
            }
            </>
          </Grid>
      </Grid>
    </Container>
  )
}
