import React,{useEffect, useState} from 'react'
import {Button,MenuItem,Select, Container ,TextField,Box, Stack, Typography, Grid, Divider, InputLabel,FormControl, FormLabel} from '@mui/material'
import Cookies from 'js-cookie';
// import { useMsal } from "@azure/msal-react";
import { getCart } from '../helpers/cart';
import useStore from '../../utils/store';

export default function Qty({qtyValue,id,product_name,setCartChange,setLoading}) {
 // console.log("qtyValue",qtyValue)
  // const { accounts } = useMsal();
  const accounts=[{username:'1',name:'1'}]

  const username = accounts[0] && accounts[0].username;
  const [qtyChoice, setQtyChoice] = React.useState({});
  const [ nameChoice, setNameChoice] = React.useState(null);
  const [productId, setProductId] = useState(id);

  const [productPersonName, setProductPersonName] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productTelephone, setProductTelephone] = useState('');
  const [productMobile, setProductMobile] = useState('');
  const [productEmail, setProductEmail] = useState('');
  const [productReceiptNo, setProductReceiptNo] = useState('');


  const location_id = Cookies.get('location_id');
  const handleChange = (event,id,field) => {

    if(field=='productQty'){

      setQtyChoice({...qtyChoice,[id]:event.target.value});
    }else if(event.target.id=='productPersonName'){
      setProductPersonName(event.target.value);
    }
    else if(event.target.id=='productTitle'){
      setProductTitle(event.target.value);
    }
    else if(event.target.id=='productTelephone'){
      setProductTelephone(event.target.value);
    }
    else if(event.target.id=='productMobile'){
      setProductMobile(event.target.value);
    }
    else if(event.target.id=='productEmail'){
      setProductEmail(event.target.value);
    }
    else if(event.target.id=='productReceiptNo'){
      setProductReceiptNo(event.target.value);
    }

  };
  const { setCartState, getCartState } = useStore();
  const handleAddToOrder = async () => {
    // console.log("qtyChoice",qtyChoice)

    if(Object.keys(qtyChoice).length==0){
      alert('Please select qty')
    }else{
      setLoading(true)
      const addCart= await fetch(`${process.env.REACT_APP_API_ENDPOINT}/cart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          product_id: productId,
          location_id: parseInt(location_id),
          qty: qtyChoice,
          productPersonName:productPersonName,
          productTitle:productTitle,
          productTelephone:productTelephone,
          productMobile:productMobile,
          productEmail:productEmail,
          productReceiptNo:productReceiptNo || 0,
          username:username,
          product_name:product_name
        })
      })
      .then(res => res.json())
      .then(data =>{
        setLoading(false)

        getCart(location_id).then((data)=>{
          setCartState(data);
        })

      }).catch((error)=>{
        setLoading(false)
        console.log("error",error)
      })
    }
  }
// console.log("qtyChoice",qtyChoice)
  return(
    <>


{id==45 &&
  <>
    <Stack spacing={1}  direction="column" sx={{width:'100%',flex:1,px:1,mb:2}}>
      <FormControl fullWidth>
        <FormLabel sx={{fontSize:10,mb:0,mt:0}}component="legend">Sequential number the book needs to start at</FormLabel>
        <TextField
        type="number"
          id="productReceiptNo"
          sx={{p:0,fontSize:10,background:'#FFF'}}
          InputProps={{
            shrink: true,
            sx:{fontSize:10,height:30}
          }}
          value={productReceiptNo}
          onChange={(e)=>handleChange(e,id,"productReceiptNo")}
        />
      </FormControl>
    </Stack>
  </>
}

    {id==10 ?
      <>
       <Stack spacing={1}  direction="column" sx={{width:'100%',flex:1,px:1}}>

        <FormControl fullWidth>
          <FormLabel sx={{fontSize:10,mb:0,mt:0}}component="legend">Qty</FormLabel>
            <Select

            sx={{p:0,height:30,fontSize:10,background:'#FFF'}}
              id="productQty"
              value={typeof qtyChoice[id]=="undefined" ? '' : qtyChoice[id]}
              onChange={(e)=>handleChange(e,id,"productQty")}
            >
              {
                qtyValue && qtyValue.map((qty,index) => (
                  <MenuItem key={index} value={qty}>{qty}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{fontSize:10,mb:0,mt:1}}component="legend">Name</FormLabel>
        <TextField
        id="productPersonName"
          sx={{p:0,fontSize:10,background:'#FFF'}}
         InputProps={{
            shrink: true,

            sx:{fontSize:10,height:30}
          }}

          value={productPersonName}
          onChange={(e)=>handleChange(e,id,"productPersonName")}
          />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{fontSize:10,mb:0,mt:1}}component="legend">Job Title</FormLabel>
        <TextField
         id="productTitle"
          sx={{p:0,fontSize:10,background:'#FFF'}}
         InputProps={{
            shrink: true,

            sx:{fontSize:10,height:30}
          }}

          value={productTitle}
          name="productTitle"
          onChange={(e)=>handleChange(e,id,"productTitle")}
          />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{fontSize:10,mb:0,mt:1}}component="legend">Telephone</FormLabel>
        <TextField
        id="productTelephone"
          sx={{p:0,fontSize:10,background:'#FFF'}}
         InputProps={{
            shrink: true,

            sx:{fontSize:10,height:30}
          }}

          value={productTelephone}
          name="productTelephone"
          onChange={(e)=>handleChange(e,id,"productTelephone")}
          />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{fontSize:10,mb:0,mt:1}}component="legend">Mobile</FormLabel>
        <TextField
          sx={{p:0,fontSize:10,background:'#FFF'}}
         InputProps={{
            shrink: true,

            sx:{fontSize:10,height:30}
          }}
          id="productMobile"
          value={productMobile}
          name="productMobile"
          onChange={(e)=>handleChange(e,id,"productMobile")}
          />
      </FormControl>
      <FormControl fullWidth>
        <FormLabel sx={{fontSize:10,mb:0,mt:1}}component="legend">Email</FormLabel>
        <TextField fullWidth
          sx={{p:0,fontSize:10,background:'#FFF'}}
          id="productEmail"
         InputProps={{
            shrink: true,

            sx:{fontSize:10,height:30}
          }}

          value={productEmail}
          name="productEmail"
          onChange={(e)=>handleChange(e,id,"productEmail")}
          />
      </FormControl>

      <Button  fullWidth sx={{p:0,height:'30px;'}} variant="contained" onClick={handleAddToOrder} size="small">Add</Button>
      </Stack>
      </>

      :<>   <Stack spacing={1}  direction="row" sx={{width:'100%',flex:1,px:1}}><FormControl fullWidth>

      <Select
      sx={{p:0,height:30,fontSize:10,background:'#FFF'}}
        id="productQty"
        value={typeof qtyChoice[id]=="undefined" ? '' : qtyChoice[id]}
        onChange={(e)=>handleChange(e,id,"productQty")}
      >
        {
          qtyValue && qtyValue.map((qty,index) => (
            <MenuItem key={index} value={qty}>{qty}</MenuItem>
          ))
        }
      </Select>
    </FormControl>   <Button sx={{p:0,height:'30px;'}} variant="contained" onClick={handleAddToOrder} size="small">Add</Button>
    </Stack>
    </>

    }





  </>
  )
}
