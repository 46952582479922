import React, { useEffect,useState } from 'react'
import {Divider,  Table,Grid, Button, Select, MenuItem,Container ,Stack,Box,InputLabel, Typography, TableHead, TableRow, TableCell,useTheme, TableBody, Link,TextField,FormControl,Checkbox,ListItemText,Chip,Alert} from '@mui/material';
import { useUser } from "@clerk/clerk-react";
import Cookies from 'js-cookie';
import useFetchUserData from '../../../hooks/useFetchUserData';
import useFetchUserDataById from '../../../hooks/useFetchUserDataById';
import useFetchLocations from '../../../hooks/useFetchLocations';
import useCreateLocation from '../../../hooks/useCreateLocation';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';



export default function CreateLocation({loading}) {


  const {createLocation,response} = useCreateLocation();

  const formik = useFormik({
    initialValues: {
      location: '',
    },
  // validationSchema: validationSchema,
  onSubmit: (values,{resetForm}) => {
    // console.log(JSON.stringify(values, null, 2));

    const location = values.location;

    createLocation({location})
    resetForm()
  },
});

console.log("response",response)
  return (
    <Container maxWidth="sm" sx={{background:'#FFF',p:3,borderRadius:2}}>
       {response && response.affectedRows > 0 && (
      <Alert severity="success" sx={{mb:2}}>The location was created</Alert>
    )}
      <Typography variant="h5" sx={{mb:2}}>Add Location</Typography>
      <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={3}>

      <TextField
        fullWidth
        id="location"
        name="location"
        label="Location"
        InputLabelProps={{ shrink: true }}
        value={formik.values.location}
        onChange={formik.handleChange}
        error={formik.touched.location && Boolean(formik.errors.location)}
        helperText={formik.touched.location && formik.errors.location}
      />



      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
      </Stack>
    </form>

    </Container>
  )
}
