import { useState, useEffect } from 'react';

function useFetchLocations(userLevel) {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (userLevel === null) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`)
        .then(res => res.json())
        .then(data => setLocations(data));
    } else {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`)
        .then(res => res.json())
        .then(data => setLocations(data));
    }
  }, [userLevel]);

  return locations;
}export default useFetchLocations;