import React, { useEffect,useState } from 'react'
import { Table,Grid,Container ,Box, Typography, TableHead, TableRow, TableCell, TableBody, Link, Chip,Stack,Button,Alert, Paper} from '@mui/material';
import { useUser } from "@clerk/clerk-react";
import useFetchUserData from '../../hooks/useFetchUserData';
import useFetchLocations from '../../hooks/useFetchLocations';
import DeleteLeft from 'src/theme/icons/DeleteLeft';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Users({loading}) {

  const { isSignedIn, user, isLoaded } = useUser();
  const [results, setResults] = useState(null);
  const [users, setUsers] = useState([]);
// console.log("user",user.emailAddresses[0].emailAddress)
// console.log("level",user?.publicMetadata?.level)

  const handleSubmit = (output) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          location_id: chosenLocation,
          order_type: orderType,
          order_date: chosenOrder,
          output: output
        })
      }).then(res => res.json()).then(data => {
        console.log("data",data)
        setResults(data)
      }).catch(err => console.log(err))
  };
  const { userData,userLevel, locationId, allowedLocations } = useFetchUserData(user.emailAddresses[0].emailAddress);
  const locations = useFetchLocations(userLevel);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const[deleteId,setDeleteId]=useState(null);
  const[deleteEmail,setDeleteEmail]=useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
const handleDelete = async (id,email) => {
    setOpen(true)
    setDeleteId(id)
    setDeleteEmail(email)
}
const handleCompleteDelete = async () => {
  fetch(`${process.env.REACT_APP_API_ENDPOINT}/userDelete/`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: deleteId,
    })
  })
  .then(res => res.json())
  .then(data => {
    console.log("data",data)
    setDeleteStatus(true)
    //waits for 4 seconds to refresh the page
    setTimeout(function(){

     window.location.reload();
   }, 2000);
  }).catch(err => console.log(err))

}
  //GET ORDERS----
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`).then(res => res.json()).then(data => {
      setUsers(data)
    })
  },[])
// console.log("users",users)

  return (
    <Container maxWidth="lg">
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {deleteStatus &&
          <>
         <Alert severity="success" sx={{mb:2}}>The user has been deleted.
         </Alert>
         <Button sx={{background:'#CCC'}}variant="contained" onClick={handleClose}>Close Window</Button>
</>
          }
          {deleteStatus==false &&
          <>
          <Typography sx={{fontWeight:600}} variant="h5" component="h5">
            Delete User
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete <strong>{deleteEmail}</strong>?
          </Typography>



          <Stack direction="row" justifyContent="space-between" sx={{mt:2}}>
            <Button sx={{background:'#CCC'}}variant="contained" onClick={handleClose}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={handleCompleteDelete}>Delete</Button>
          </Stack>
          </>
}
        </Box>
      </Modal>
    </div>
      <Grid container>
        <Grid item={true} xs={12} sm={12} md={12}lg={12}>
            <>
            <Stack direction="row" justifyContent="flex-end">
            <Button href="/createUser" variant="contained" sx={{mb:2,
            cursor:'pointer',
            '&:hover':{
              color:'#FFF'
            }
            }}>Create User</Button>
            </Stack>

            {users==null ?
              <Box sx={{p:2}}>
                <Typography>There are no users</Typography>
              </Box>
            :
            <Paper sx={{background:'#FFF'}}>
            <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{fontWeight:'bold'}}>Email</TableCell>
                      <TableCell sx={{fontWeight:'bold'}}>Level</TableCell>
                      <TableCell sx={{fontWeight:'bold'}}>Location</TableCell>
                      <TableCell sx={{fontWeight:'bold'}}>ManageLocations</TableCell>
                      <TableCell sx={{width:10}}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {users && users.map((userX,index) => {
                  const locationTab=locations.filter(location => location.id == userX.location_id)
//  console.log("userX",users)
                    if(userX.level =='superadmin'){}else{
                    return (
                    <TableRow key={index} sx={{verticalAlign:'top'}}>
                        <TableCell sx={{width:10}}>
                          <Link href={`/editUser/${userX.id}`}>{userX.username}</Link>
                        </TableCell>
                        <TableCell sx={{width:10}}>{userX.level}</TableCell>
                        <TableCell sx={{width:10}}>{locationTab.length>0 ? locationTab[0].name :'N/a'}</TableCell>
                        <TableCell  >
                        <Stack justifyContent="flex-start"direction="row"  flexGap flexWrap="wrap">
                          {userX.manageLocations !=null &&
                          userX.manageLocations.split(',').map((loc,index) => {

                            const locationTabs=locations.filter(locationX => locationX.id == loc)

                            return (

                              <Chip size="small" sx={{flexGrow:1,fontSize:9,p:0.5,mb:1}} label={locationTabs.length>0 ? locationTabs[0].name :'N/A'} ></Chip>

                            )
                          })
                          }
                          </Stack>
                        </TableCell>
                        <TableCell sx={{width:10}}><DeleteLeft sx={{cursor:'pointer','&:hover':{opacity:0.5,transition:'all 0.3s ease-in-out'
                  }}} onClick={(e)=>handleDelete(userX.clerk_id,userX.username)} /></TableCell>
                      </TableRow>
                    )
                  }
                    })}
                  </TableBody>
                </Table>
                </Paper>

            }
            </>
          </Grid>
      </Grid>
    </Container>
  )
}
