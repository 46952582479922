export const getCart = async (location_id) => {
  const results = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/getCart`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      location_id: location_id,
    })
  }).then(res => res.json()).then(data => {

    return data
  });
  return results
}