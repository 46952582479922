import React, { useEffect,useState } from 'react'
import {Divider,  Table,Grid, Button, Select, MenuItem,Container ,Stack,Box,InputLabel, Typography, TableHead, TableRow, TableCell,useTheme, TableBody, Link,TextField,FormControl,Checkbox,ListItemText,Chip,Alert} from '@mui/material';
import { useUser } from "@clerk/clerk-react";
import Cookies from 'js-cookie';
import useFetchLocationData from '../../../hooks/useFetchLocationData';
import useFetchUserDataById from '../../../hooks/useFetchUserDataById';
import useFetchLocations from '../../../hooks/useFetchLocations';
import useEditLocation from '../../../hooks/useEditLocation';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';



export default function EditLocation({loading}) {

  const { u } = useParams();
  const [initialFormValues, setInitialFormValues] = useState({
    location: '',
  });
  const { location } = useFetchLocationData(u);

  const {editLocation,response,error} = useEditLocation();

//   useEffect(() => {
//     setInitialFormValues({location:location})

// ,[location]})
useEffect(() => {
if (location && location.length>0) {
  setInitialFormValues({
    location:location[0].name,
  });
}
}, [location]);


console.log("initialFormValues",location)
  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
  // validationSchema: validationSchema,
  onSubmit: (values) => {
    // console.log(JSON.stringify(values, null, 2));
    const id = u;
    const location = values.location;

    editLocation({id:id,name:location})

  },
});


  return (
    <Container maxWidth="sm" sx={{background:'#FFF',p:3,borderRadius:2}}>
      {response && response?.data && response?.data?.affectedRows > 0 && (
      <Alert severity="success" sx={{mb:2}}>The location was updated</Alert>
    )}
      <Typography variant="h5" sx={{mb:2}}>Edit Location</Typography>
      <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={3}>

      <TextField
        fullWidth
        id="location"
        name="location"
        label="Location"
        InputLabelProps={{ shrink: true }}
        value={formik.values.location}
        onChange={formik.handleChange}
        error={formik.touched.location && Boolean(formik.errors.location)}
        helperText={formik.touched.location && formik.errors.location}
      />



      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
      </Stack>
    </form>

    </Container>
  )
}
