import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useFetchUserData = (username) => {
  const [userData, setUserData] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [allowedLocations, setAllowedLocations] = useState([]);
  const [userLevel, setUserLevel] = useState(null);
  useEffect(() => {
    if (username) {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/user`, {
        method: "POST",
        body: JSON.stringify({ username }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          Cookies.set('location_id', data[0].location_id);
          setLocationId(data[0].location_id);
          setUserData(data[0]);
          setUserLevel(data[0].level);
          if(data[0].manageLocations !=null){
            setAllowedLocations(data[0].manageLocations.split(','));
          }

        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
    }
  }, [username]);

  return { userData,userLevel, locationId, allowedLocations };
};

export default useFetchUserData;