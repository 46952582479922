import { useState} from 'react';

function useEditUser(userDAta) {
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const editUser = (userData) => {
    setIsLoading(true);
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/userUpdate`,
      {
        method: "PUT",
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => response.json())
      .then(data => {
        setResponse(data);
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
      });
    }
      return { editUser, response, error, isLoading };

}
export default useEditUser;