import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

const useFetchUserDataById = () => {
  const [userDataById, setUserData] = useState(null);
  const [userEmailById, setUserEmail] = useState(null);
  const [userLocationIdById, setLocationId] = useState(null);
  const [userAllowedLocationsById, setAllowedLocations] = useState([]);
  const [userLevelById, setUserLevel] = useState(null);
  const { u } = useParams();
  useEffect(() => {
    if (u) {

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/userById`, {
        method: "POST",
        body: JSON.stringify({ id:u }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data",data)
        if (data.length > 0) {
          Cookies.set('location_id', data[0].location_id);
          setLocationId(data[0].location_id);
          setUserData(data[0]);
          setUserEmail(data[0].username);
          setUserLevel(data[0].level);
          if(data[0].manageLocations !=null){

            setAllowedLocations(data[0].manageLocations.split(','));
          }

        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
    }
  }, [u]);
  return { userEmailById,userDataById,userLevelById, userLocationIdById, userAllowedLocationsById };
};

export default useFetchUserDataById;