import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.jsx";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App />
            </ThemeProvider>

    </React.StrictMode>,
    document.getElementById("root")
);
