import { Container ,Box, Stack, Typography, Grid, Divider, InputLabel, TableRow, TableHead, TableCell,Table, TableBody, Skeleton,Alert} from '@mui/material'
import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Chip from '@mui/material/Chip';

import Cookies from 'js-cookie';
import {getCart} from '../helpers/cart';
import Qty from './Qty';
import useStore from '../../utils/store';
import DeleteLeft from '../../theme/icons/DeleteLeft';
// import 'lightbox.js-react/dist/index.css'
import moment from 'moment';
// import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'
import parse from 'html-react-parser';
import IconChevron from '../Icon';
export default function ShopFront() {
  const [categories, setCategories] = useState([]);
  const [categoriesNotes, setCategoriesNotes] = useState([]);
  const location_id = Cookies.get('location_id');
  const [cartChange, setCartChange] = useState(0);
  const[localCart,setLocalCart]=useState([]);
  const { cart,setCartState, getCartState } = useStore();
  const [loading,setLoading]=useState(false);
  const [productsLoading,setProductsLoading]=useState(false);
  const [cartLoading,setCartLoading]=useState(false);
  // useEffect(() => {
  //   initLightboxJS("BEF8-4D10-3BBB-18DD", "team");
  // });
  //LOAD PRODUCTS---------------------

  useEffect(() => {
    setProductsLoading(true)
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/categories`)
      .then(res => res.json())
      .then(data => {
        setCategories(data)
        setProductsLoading(false)
      });
  }, []);

  useEffect(() => {
    setProductsLoading(true)
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/categoriesNotes`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        location_id: location_id
      })
    })
      .then(res => res.json())
      .then(data => {
        setCategoriesNotes(data)
        setProductsLoading(false)
      });
  }, []);

  //LOAD CART---------------------
  useEffect(async () => {
    setCartLoading(true);
    const cartData= await getCart(location_id);
    setCartState(cartData);
    setLocalCart(cartData);
    setCartLoading(false);
  }, [location_id,cartChange]);

  useEffect(() => {
    setLocalCart(cart);
  }, [cart]);

  const handleDelete = async (id) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/product/${id}`,{
      method: 'DELETE'
    })
    .then(res => res.json())
    .then(data => {
      getCart(location_id).then((data)=>{
        setCartState(data);
        setLocalCart(data);
      })
    });
  }
// console.log("categories",categories)
 //console.log("cartData",cart)
//console.log("masterProduct",categories)

// Get the current date
const now = moment();
// Get the current day of the month as a number
const dayAsNumber = now.date();
const notes = [
  {
      "category": "1",
      "note": "<p>The following forms (England and Wales), can be printed off the Ministry of Justice website:</p>\t\t\n<p>Form 2 - <a href=\"https://www.gov.uk/government/publications/apply-to-cremate-body-parts\">Application for Cremation of Body Parts:</a></p>\n<p>Form 3 - <a href=\"https://www.gov.uk/government/publications/apply-to-cremate-a-stillborn-baby\">Application for cremation of stillborn baby</a></p>   \n<p>Form 10 - <a href=\"https://www.gov.uk/government/publications/authorise-the-cremation-of-a-dead-person\">Authorisation of cremation of deceased person by medical referee</a></p>        ",
      "order": 1
  },
  {
      "category": "3",
      "note": "<p>To order EverWith brochures, please email <a href=\"mailto:Helen@everwith.co.uk\">Helen@everwith.co.uk</a></p>",
      "order": 1
  },
  {
      "category": "4",
      "note": "<p>NB: A2 poster frames can be ordered via Lyreco, product code: 15.638.059</p>",
      "order": 1
  },
  {
      "category": "3",
      "note": "<p>To order Aura Flights brochures, please email <a href=\"mailto:elle@auraflights.com\">elle@auraflights.com</a></p>",
      "order": 2
  }
]
// console.log("categoriesNotes",categoriesNotes)
const relevantNotes = categoriesNotes.filter(note => {
  const locations = note.locations.split(',').map(Number); // Convert locations to an array of numbers

// console.log("locations",locations)
  if (locations.includes(0)) {
    // Note is relevant to all locations
    return true;
  } else if (locations[0] < 0) {
    // Note is relevant to all locations except those listed
    return !locations.includes(-location_id);
  } else {
    // Note is only relevant to the locations listed
    return locations.includes(location_id);
  }
}).map(note => ({
  category: note.category,
  note: note.note,
  order: note.order
}));
// console.log("relevantNotes",relevantNotes)
  return (

    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} md={8} lg={8}>
      <Container maxWidth={'md'}>
      <Alert severity="info" sx={{border:'1px solid #174b64',fontWeight:600,fontSize:14,mb:1,mt:0}}>The cut-off date for monthly stationery orders is the 5th of each month. If this date falls on the weekend, the cut-off date will be the Friday prior.</Alert>
    </Container>
        {productsLoading ?
        <Container maxWidth={'md'}>
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}}/>
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}} />
            <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}}/>
        </Container> :
        <Container maxWidth={'md'}>
           {categories && categories.masterProducts && categories.masterProducts.map((masterProduct,index) => {

             const validLocations = masterProduct.categoryNotesLocation && masterProduct.categoryNotesLocation.split(',');
             const negativeLocation=parseInt(location_id)*-1;
             const locationMatch = validLocations && !validLocations.includes(negativeLocation);

             return(
               <Accordion key={index} sx={{boxShadow:'none',mb:1,textAlign:'left'}}>
               <AccordionSummary
               sx={{borderRadius:2,borderTop:0,width:'100%',}}
                 expandIcon={<IconChevron sx={{fontSize:10}} />}
                 aria-controls="panel1a-content"
                 id="panel1a-header"
               >
               <Stack direction="row" justifyContent={"space-between"} sx={{width:'100%'}}>
                 <Typography variant="h5" sx={{fontSize:14,fontWeight:600,textAlign:'left',mb:0}}>{masterProduct.categoryName}</Typography>
                 <Typography sx={{textAlign:'right',fontSize:10,}}>Click to expand</Typography>
                </Stack>



               </AccordionSummary>
               <AccordionDetails>
{/* <Divider sx={{mb:2,height:2,borderSize:2}} /> */}
<Typography sx={{fontStyle:'italic',fontSize:11,mb:1,mt:0}}>Please note product visuals are for demonstration purposes only, any site specific products will be correctly branded to your location.</Typography>

              {/* {masterProduct.categoryNotes !==null && locationMatch &&
                 <Box sx={{'& > p':{fontSize:12,mb:1}}}>{parse(masterProduct.categoryNotes)}</Box>
               } */}


      {relevantNotes.filter(note => note.category ==masterProduct.categoryId)
            .sort((a, b) => a.order - b.order)
            .map((note, index) => (
        <Box key={index} sx={{mb:2,'& p':{
          fontSize:12,marginBottom:0.5
        }}}>
           {parse(note.note)}
        </Box>
      ))}


             {/* <Box key={index} sx={{mb:8,textAlign:'left'}}> */}

               <Grid container spacing={2}>
               {masterProduct.products.map((product,indexer) => {
                 let output='';
                 const productLocations = product.locations && product.locations.split(',');
                 const locationIdString = String(location_id);
                 //console.log("locationIdString",locationIdString)
                 if(productLocations && productLocations.includes(locationIdString)){

                   output=<Grid item xs={12} sm={6} md={4} lg={3} key={indexer}><Stack spacing={1}direction="column" alignItems="flex-start" justifyContent="space-between" sx={{background:'#fff',p:1,borderRadius:2,boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>

                   <Box sx={{background:'#F0E5CC',width:'100%',p:1,borderRadius:1,minHeight:120,}}>
                   <Typography variant="h6" sx={{color:'#375dc3',fontWeight:600,minHeight:45,fontSize:14,textAlign:'left',pl:1.2}}>{product.name}</Typography>
                   {
                     product && product.quantities &&
                     <Qty setLoading={setLoading} setCartChange={setCartChange} id={product.id} product_name={product.name} qtyValue={(product.quantities.trim()).split(',')}  />
                   }
                   </Box>
                   <Box sx={{maxheight:250, boxShadow: '0px 3px 15px rgba(0,0,0,0.2)'}}>
                   {/* <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto' > */}
                     <img className='w-full rounded' src={`./products/${product.image}`} style={{objectPosition:'top center',minHeight:150,maxheight:150,objectFit:'cover',width:'100%'}} />
                   {/* </SlideshowLightbox> */}
                   </Box>
                 </Stack>
                 </Grid>
                 }else{

                   output='';
                 }
                 //console.log("productLocations",productLocations)
                 return output
               })}
               {/* {masterProduct.products.map((product,indexer) => (


                 </Grid>
               ))} */}
               </Grid>
             {/* </Box> */}
             </AccordionDetails>
     </Accordion>
             )
           })}
        </Container>
        }
      </Grid>
      <Grid item xs={12} sm={3} md={4} lg={4} >
        <Box sx={{background:'#FFF',p:3,borderRadius:2,position:'sticky',top:'20px',height:'calc(80vh-20px)'}}>
          <Stack direction={'row'} alignItems="center" justifyContent="space-between" sx={{mb:2}}>
          <Typography variant="h5" sx={{fontWeight:600}}>Your Order for {dayAsNumber<6 ? moment().subtract(1, 'months').format("MMM"):moment().format("MMM")}</Typography>
            <Tooltip sx={{cursor:'pointer'}} title="Simply add the items you wish to order to the list below. At the end of the month we will place the order and reset the values for the following month.">
            <Chip onClick={()=>location.href="mailto:marketing@westerleighgroup.co.uk"} sx={{cursor:'pointer'}}  label="Help" color="primary"></Chip>
            </Tooltip>
          </Stack>

          <Divider />
          <Box sx={{height:'100%',overflowY:'auto',mt:2}}>
            {cartLoading ?

            <>
              <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}} />
          <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}}/>
          <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}} />
          <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}} />
          <Skeleton variant="rectangular" width={'100%'} height={50} sx={{mb:1}}/>
            </>
              : null}
            <Table sx={{border:'1px solid #f0f1f5'}}>
              <TableHead>
                <TableRow sx={{p:0,background:'#f0f1f5'}}>
                  <TableCell sx={{width:50,fontWeight:'bold',p:0,px:1}}>Qty</TableCell>
                  <TableCell sx={{fontWeight:'bold',p:0,px:1}}>Product</TableCell>
                  <TableCell sx={{width:50,fontWeight:'bold',p:0,px:1}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localCart && localCart.length>0 && localCart.map((item,index) => (
                  <TableRow key={index}  sx={{p:0}}>
                  <TableCell  sx={{p:1}}>{item.qty}</TableCell>
                  <TableCell  sx={{p:1}}>{item.product_name}<br />
                  {item.product_id==45 &&
                  <Typography sx={{fontSize:10}}>Receipt no starts from: {item.receiptNo}</Typography>
                  }
                  {item.product_id==10 ?
                  <Typography sx={{fontSize:10}}>{item.personName}<br />{item.personTitle}<br />{item.personEmail}<br />{item.personTelephone}<br />{item.personMobile}

                  </Typography>
                  : null
                  }</TableCell>
                  <TableCell  sx={{p:1,cursor:'pointer'}}><DeleteLeft sx={{cursor:'pointer','&:hover':{opacity:0.5,transition:'all 0.3s ease-in-out'
                  }}} onClick={(e)=>handleDelete(item.id)} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Grid>
      </Grid>

  )
}
